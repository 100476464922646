/* https://www.bootstrapdash.com/demo/justdo/template/demo/vertical-default-dark/pages/tables/data-table.html */
/* :root{
    --bs-body-bg: #1e1e2f;
    --body-color: #fff;

    --card-bg-color: #27293d;
    --card-border: none;
    --card-header-title-color: #b1b1b5;

    --table-border-color: #424351;
    --table-font-color: #b1b1b5;
    --table-head-bg-color: var(--card-bg-color);
    --table-head-font-color: var(--table-font-color);
    --new-ticker-bg-color: #31355e;
    --news-font-color: #ff8acc;
} */


/* https://demos.creative-tim.com/black-dashboard-pro-react/#/admin/react-tables */
/* :root{
    --bs-body-bg: #1e1e2f;
    --body-color: #fff;

    --card-bg-color: #27293d;
    --card-border: 0;
    --card-header-title-color: hsla(0,0%,100%,.8) !important;

    --table-border-color: #dee2e6 hsla(0,0%,100%,.1) hsla(0,0%,100%,.1);
    --table-font-color: hsla(0,0%,100%,.7) !important;
    --table-head-bg-color: var(--card-bg-color);
    --table-head-font-color: var(--table-font-color);
    --new-ticker-bg-color: #2d4059;
    --news-font-color: #ff8acc;
} */



/* https://www.bootstrapdash.com/demo/polluxui/template/demo/vertical-default-dark/pages/tables/data-table.html */
/* :root{
    --bs-body-bg: #1c1e2f;
    --body-color: #fff;

    --card-bg-color: #222437;
    --card-border: 1px solid #313452;
    --card-header-title-color: var(--table-font-color);

    --table-border-color: #2a2c40;
    --table-font-color: #b1b1b5;
    --table-head-bg-color: var(--card-bg-color);
    --table-head-font-color: var(--table-font-color);
    --new-ticker-bg-color: #3a3f68;
    --news-font-color: #ff8acc;
} */



/* https://www.bootstrapdash.com/demo/allurui/template/demo/vertical-default-dark/pages/tables/data-table.html */
html[data-theme='dark'] {
    --bs-body-bg: #0f111d;
    --body-color: #fff;

    --card-bg-color: #181b2e;
    --card-border: 0 solid #313844;
    --card-header-title-color: var(--table-font-color);

    --table-border-color: #3a4250;
    --table-font-color: #c8c6c6;
    --table-head-bg-color: var(--card-bg-color);
    --table-head-font-color: var(--table-font-color);
    --new-ticker-bg-color: #222959;

    --form-input-bg-color: #2b2e4c;
    --form-input-color: var(--table-font-color);
    --form-input-border: 1px solid #292c3c;
    --form-input-border-focus: 1px solid #86b7fe;
    --news-font-color: #ff8acc;
}


/* https://coderthemes.com/adminto/layouts/creative/tables-datatables.html */
html[data-theme='monokai'] {
    --bs-body-bg: rgb(40 46 56);
    --body-color: #fff;

    --card-bg-color: #313844;
    --card-border: 0 solid #313844;
    --card-header-title-color: white;

    --table-border-color: #3a4250;
    --table-font-color: white;
    --table-head-bg-color: #313844;
    --table-head-font-color: var(--table-font-color);
    --new-ticker-bg-color: #2d4059;

    --form-input-bg-color: #363e4b;
    --form-input-color: var(--table-font-color);
--form-input-border: 1px solid #424c5c;
    --form-input-border-focus: 1px solid #576479;
    --news-font-color: #ff8acc;
}


/* https://coderthemes.com/hyper_2/modern/tables-datatable.html */
/* Light Theme */
html[data-theme='light'] {
    --bs-body-bg: #fff;
    --body-color: #000;

    --card-bg-color: #fff;
    --card-border: 1px solid rgba(0,0,0,.125);
    --card-header-title-color: #343a40;

    --table-border-color: #dee2e6;
    --table-font-color: #212529;
    --table-head-bg-color: #212529;
    --table-head-font-color: #fff;
    --new-ticker-bg-color: rgb(255, 182, 193);

    --form-input-bg-color: rgb(226, 226, 226);
    --form-input-color: var(--table-font-color);
    --form-input-border: 1px solid #ced4da;
    --form-input-border-focus: 1px solid #b1bbc4;
    --news-font-color: #f21090;
}

:root {
    --bs-body-bg: #0f111d;
    --body-color: #fff;

    --card-bg-color: #181b2e;
    --card-border: 0 solid #313844;
    --card-header-title-color: var(--table-font-color);

    --table-border-color: #3a4250;
    --table-font-color: #c8c6c6;
    --table-head-bg-color: var(--card-bg-color);
    --table-head-font-color: var(--table-font-color);
    --new-ticker-bg-color: #222959;

    --form-input-bg-color: #2b2e4c;
    --form-input-color: var(--table-font-color);
    --form-input-border: 1px solid #292c3c;
    --form-input-border-focus: 1px solid #86b7fe;
    --news-font-color: #ff8acc;
}

.tableFixHead          { overflow: auto; height: 100px; }
.tableFixHead thead tr th { position: sticky; top: 0; z-index: 1; }

body{
    background-color: var(--bs-body-bg);
    color: var(--body-color);
}

.table{
    color: var(--table-font-color);
    border-color: var(--table-border-color);
    font-size: 0.85rem;
}

.table-header{
    position: sticky;
    top: 0;
    z-index: 1;
    border-color: #dee2e6;
    color: var(--table-head-font-color);
    background-color: var(--table-head-bg-color);
  }

.code-font{
    font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    color: var(--news-font-color);
    font-size: 0.8rem;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: var(--table-border-color);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: var(--bs-body-bg);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--form-input-bg-color);
    border-radius: 10px;
    border: none;
  }