.card {
    background-color: var(--card-bg-color);
    box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
    margin-bottom: 1.5rem;
    border: var(--card-border);
    border-radius: 0.25rem;
}

.card-header {
    font-size: 0.9rem;
    margin: 0 0 7px 0;
    color: var(--card-header-title-color);
}

.dragHandle {
    cursor: move;
    font-size: 1rem;
}

.form-input {
    background-color: var(--form-input-bg-color);
    color: var(--form-input-color);
    display: block;
    width: 100%;
    padding: 0.875rem 1.375rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    background-clip: padding-box;
    border: var(--form-input-border);
    appearance: none;
    border-radius: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.date-picker-form {
    height: 30px;
    padding-left: 8px;
    font-size: 0.9rem;
}

.react-datepicker__close-icon {
    display: none !important
}

.form-input:focus {
    border: var(--form-input-border-focus);
}

.setting-icon {
    cursor: pointer;
    transition: transform .7s ease-in-out;
}

.setting-icon:hover {
    transform: rotate(360deg);
}

.image-capture-icon {
    font-size: 1.1rem;
    margin: 5px 10px 0px;
    cursor: pointer;
}

.modal-content {
    color: black;
}


.switcher {
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 8px;
    color: #2196F3;
}

.switcher-item {
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    padding: 6px 8px;
    font-size: 14px;
    color: #b2b5be;
    background-color: transparent;
    margin-right: 2px;
    border: none;
    border-radius: 4px;
    outline: none;
}

.switcher-item:hover {
    background-color: #2a2e39;
}

.switcher-active-item {
    text-decoration: underline;
    cursor: default;
    color: #0d6efd;
    background-color: transparent !important;
}

.switcher-active-item,
.switcher-active-item:hover {
    background-color: #e1eff9;
}

.header-button {
    color: white;
    background: linear-gradient(to bottom, #d41459, #911a6c);
    padding: 4px 6px;
    text-decoration: none;
    margin: 0 5px;
    border-radius: 5px;
    opacity: 1;
    cursor: pointer;
    font-size: 14px;
}

.header-button:hover {
    color: white;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*padding: 20px;*/
    border: var(--card-border);
    border-width: 2px;
    border-radius: 10px;
    border-style: dashed;
    background-color: var(--card-bg-color);
    outline: none;
    height: 100%;
    transition: border .24s ease-in-out;
}

.dropzone:focus {
    border-color: #2196f3;
}

.dropzone:hover {
    border-color: #2196f3;
}

.recharts-custom-tooltip {
    background-color: black;
    padding: 8px;
    font-size: 12px
}

.loader-demo-box {
    width: 100%;
}

.dot-opacity-loader {
    width: 100px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}

.dot-opacity-loader span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #fc4a9e;
    margin: 0px 5px;
    opacity: 0;
}

.dot-opacity-loader span:nth-child(1) {
    animation: opacitychange 1s ease-in-out infinite;
}

.dot-opacity-loader span:nth-child(2) {
    animation: opacitychange 1s ease-in-out 0.33s infinite;
}

.dot-opacity-loader span:nth-child(3) {
    animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange {

    0%,
    100% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }
}

.panel-fullscreen {
    z-index: 9999 !important;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    overflow: auto;
    margin: auto;
}

.login-with-google-btn {
    transition: background-color .3s, box-shadow .3s;

    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
}

login-with-google-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

login-with-google-btn:active {
    background-color: #eeeeee;
}

login-with-google-btn:focus {
    outline: none;
    box-shadow:
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

login-with-google-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}

.box-shadow {
    animation: box-shadow-effect 0.5s ease-in-out;
}

@keyframes box-shadow-effect {
    0% {
        box-shadow: none
    }

    50% {
        box-shadow: 0px 1px 15px 2px #0d6efd
    }

    100% {
        box-shadow: none
    }
}

.ticker-high-animation {
    animation: ticker-high-animation-effect 0.5s linear;
}

@keyframes ticker-high-animation-effect {
    0% {
        color: inherit
    }

    50% {
        color: green
    }

    100% {
        color: inherit
    }
}

.ticker-low-animation {
    animation: ticker-low-animation-effect 0.5s linear;
}

@keyframes ticker-low-animation-effect {
    0% {
        color: inherit
    }

    50% {
        color: red
    }

    100% {
        color: inherit
    }
}


.home-header-button-margin {
    margin-left: 40px
}

.footer-before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.footer-before img {
    width: 100%;
}

.footer-section {
    position: relative;
    overflow: hidden;
}

.bg_img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.footer-link {
    position: relative;
    z-index: 2;
}

.footer-link div {
    position: relative
}

.footer-link span {
    margin: 2px 15px;
    width: 2px;
    height: 19px;
    background: #5e5bb7;
}

.footer-link a {
    color: white;
    text-decoration: none
}

.footer-link a:hover {
    color: #ff4343;
}

.copyright {
    text-align: center;
    color: #fff;
    padding: 22px 0;
    border-top: 1px solid #49329b;
}

.top-section {
    font-family: "Josefin Sans", sans-serif;
    position: relative;
}

.top-section .title {
    font-weight: 700;
    line-height: 42px;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.top-section p {
    font-size: 1.3em;
    max-width: 480px;
    margin: 0 auto;
    margin-bottom: 60px;
    color: #C0C0C0;
}

.top_after_img_div {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.top_after_img {
    width: 100%;
    max-height: 275px;
}

.scanner_bg_img {
    margin-top: 60px;
    -webkit-border-radius: 30px !important;
    -moz-border-radius: 30px !important;
    border-radius: 30px !important;
}

.scanner-button {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    color: #fff;
    padding: 15px 45px;
    background: -ms-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
    background: -moz-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
    background: -webkit-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
}

.scanner-button:hover {
    color: #fff;
    box-shadow: 0 17px 24px 0 rgba(18, 83, 252, .51);
}

.pnl-trendline-img {
    transform: skewX(-10deg) rotateZ(-10deg);
}

.pnl-daily-img {
    transform: skewX(10deg) rotateZ(10deg);
}

.contact-wrapper {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background: #31377d;
    box-shadow: 0 11px 19.2px .8px rgba(66, 58, 232, .1);
    padding: 65px 40px 10px;
    margin-bottom: 80px;
    z-index: 3;
}

.contact-form .form-group {
    margin-bottom: 23px;
}

.contact-wrapper h4 {
    margin-bottom: 15px;
}

.contact-form .form-group label {
    font-size: 18px;
    font-weight: 600;
    font-family: "Josefin Sans", sans-serif;
    color: #fff;
    display: inline-block;
    margin-bottom: .5rem;
    cursor: pointer;
}

.contact-form .form-group input {
    border: 1px solid rgba(59, 54, 140, .1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: #f6f6fa;
    padding: 0 30px;
    height: 60px;
    color: #3b368c;
    width: 100%
}

.contact-form .form-group textarea {
    border: 1px solid rgba(59, 54, 140, .1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: #f6f6fa;
    padding: 30px;
    height: 140px;
    resize: none;
    width: 100%;
    overflow: auto
}

.contact-form .form-group input[type=submit] {
    background: -moz-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
    background: -ms-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
    background: -webkit-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
    width: auto;
    padding: 0 50px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 20px auto;
    display: block;
    border: none;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    box-shadow: 0 10px 30px rgba(118, 93, 234, .502);
    color: #fff;
}

.contact-form .form-group label {
    margin: 0;
    padding-left: 10px;
    width: calc(100% - 22px);
    font-size: 16px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
}

.contact-form .formSubmitMessage {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

#uppercase {
    text-transform: uppercase
}

.react-select__input {
    min-width: 100% !important
}




@media all and (max-device-width: 720px) {
    .top-section .title {
        font-size: 32px;
        line-height: initial;
    }

    .top-section p {
        font-size: 14px;
    }

    .home-header-button-margin {
        margin-left: 0
    }
}

@media all and (max-device-width: 639px) {
    .image-capture-icon {
        display: none
    }
}